import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/jedi-survivor',
    name: 'jedi-survivor',
    component: () => import(/* webpackChunkName: 'ea-jedi-survivor' */ '../../views/ea-jedi-survivor/Index.vue'),
    props: {
      gaEventCategory: 'ea_star_wars_js',
      gaEventLabel
    }
  },
  {
    path: '/thesaberhunt',
    name: 'saberhunt',
    component: () => import(/* webpackChunkName: 'ea-saberhunt' */ '../../views/ea-saberhunt/Index.vue'),
    props: {
      gaEventCategory: 'ea_star_wars_saberhunt',
      gaEventLabel
    }
  }
]
