const rootDomain = process.env.VUE_APP_GIVEAWAY_DOMAIN || 'giveaways.joinsurf.com'

export default {
  links: {
    home: 'https://www.joinsurf.com/giveaways',
    contact: 'https://www.joinsurf.com/brands/contact-us',
    dashboard: `https://dashboard.${rootDomain}/`,
    discord: 'https://discord.gg/kheBTUAGf9',
    giveaways: `https://${rootDomain}/`,
    giveawayBase: `https://${rootDomain}/giveaway`,
    login: `https://${rootDomain}/login`,
    merch: 'https://joinsurf.shop/',
    privacy: 'https://www.joinsurf.com/giveaways/privacy-policy',
    pricing: 'https://www.joinsurf.com/brands/pricing',
    terms: 'https://www.joinsurf.com/giveaways/terms-of-use'
  },
  email: {
    support: 'success@joinsurf.com'
  },
  apollo: {
    graphql: process.env.VUE_APP_GRAPHQL_URL || 'https://api.giveaways.joinsurf.com/graphql'
  },
  social: {
    instagram: 'surfgiveaways',
    facebook: 'surfgiveaways',
    twitter: 'surfgiveaways',
    linkedin: 'JoinSurf'
  }
}
