<template lang="pug">
header.mainnav.surf-nav
  .mainnav-wrap
    b-container.mainnav-container(:fluid='true')
      b-navbar.mainnav__navbar(type='dark')
        b-navbar-brand.mainnav__navbar-brand(:class='{ "w-100": !showHeaderLinks }')
          slot(name='branding')
            surf-logo.d-inline-block(
              size='xs',
              :link-to-home='true',
              :link-new-tab='true',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )
        b-nav#mainnav-desktop.align-items-center(v-if='showHeaderLinks')
          b-nav-item(:href='$surf.links.giveaways', @mousedown='trackEvent("to_browse")', target='_blank')
            .d-inline-flex.align-items-center.fw-heavy
              i.icon.icon-sm.icon-inline.mr-2(:class='$icons.prize')
              | Browse Giveaways
</template>
<script>
import gaEventMixin from '../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  props: {
    showHeaderLinks: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
