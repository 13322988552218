import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/challenge-response',
    name: 'challenge-response',
    component: () => import(/* webpackChunkName: 'challenge-response' */ '../../views/ChallengeResponseTest.vue'),
    props: {
      gaEventCategory: 'challenge-response',
      gaEventLabel
    }
  }
]
