export default {
  data() {
    return {
      eventCategory: null,
      eventLabel: null
    }
  },
  props: {
    gaEventCategory: {
      type: String,
      required: false,
      default: null
    },
    gaEventLabel: {
      type: String,
      required: false,
      default: null
    },
    gaEventActionPrefix: {
      type: String,
      required: false,
      default: null
    },
    gaEventActionSuffix: {
      type: String,
      required: false,
      default: null
    }
  },
  watch: {
    gaEventCategory() {
      this.gaSetEventCategory(this.gaEventCategory)
    },
    gaEventLabel() {
      this.gaSetEventLabel(this.gaEventLabel)
    }
  },
  created() {
    this.gaSetEventCategory(this.gaEventCategory)
    this.gaSetEventLabel(this.gaEventLabel)

    this.trackEvent = this.gaEventSetup()
  },
  methods: {
    gaSetEventCategory(category, force = false) {
      if (!!category || force) {
        this.eventCategory = category
      }
    },
    gaSetEventLabel(label, force = false) {
      if (!!label || force) {
        this.eventLabel = label
      }
    },
    gaEventSetup(category, label = null) {
      return (action, value) => this.gaFireEvent(action, category, label, value)
    },
    gaFireEvent(eventAction, category = null, label = null, value = null) {
      const eventCategory = category ? category : this.eventCategory
      const eventLabel = label ? label : this.eventLabel
      const eventValue = value ? value : 0

      if (!eventCategory || !eventAction) {
        return
      }

      const eventExtras = {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue
      }

      if (!eventLabel) {
        delete eventExtras.eventLabel
      }

      if (!eventValue) {
        delete eventExtras.value
      }

      // console.debug('event: %o', { event_action: eventAction, ...eventExtras })

      this.$gtag?.event(eventAction, eventExtras)
    }
  }
}
