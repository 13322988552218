import surfConfig from '@/config/surf'

export default {
  giveawayUrl(idToken) {
    return `${surfConfig.links.giveawayBase}/${idToken}`
  },
  scrollToId(id) {
    const el = document.getElementById(id)

    if (el) {
      el.scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  addClassesToWordInCopy(copy, word, classes) {
    const wordPos = copy.toLowerCase().indexOf(word)

    if (!word.length || wordPos === -1) {
      return copy
    }

    return `${copy.substring(0, wordPos)}<span class="${classes}">${copy.substring(
      wordPos,
      wordPos + word.length
    )}</span>${copy.substring(wordPos + word.length, copy.length)}`
  },
  async asyncForEach(array, callback) {
    // Equivalent to an async/await "forEach" call where we can do sequential processing,
    // since a normal "forEach" can't do this in the way we want.
    await array.reduce(async (memo, item) => {
      await memo
      await callback(item)
    }, undefined)
  }
}
