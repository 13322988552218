import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

const basePath = '/the-game-awards'
const commonProps = {
  gaEventCategory: 'ea_vgas',
  gaEventLabel
}

export default [
  {
    path: basePath,
    name: 'ea_vgas',
    component: () => import(/* webpackChunkName: 'ea-vgas' */ '../../views/ea-vgas/Index.vue'),
    props: commonProps
  },
  {
    path: `${basePath}/global`,
    name: 'ea_vgas_global',
    component: () => import(/* webpackChunkName: 'ea-vgas-global' */ '../../views/ea-vgas/regions/Global.vue'),
    props: commonProps
  },
  {
    path: `${basePath}/americas`,
    name: 'ea_vgas_americas',
    component: () => import(/* webpackChunkName: 'ea-vgas-na' */ '../../views/ea-vgas/regions/Americas.vue'),
    props: commonProps
  },
  {
    path: `${basePath}/europe`,
    name: 'ea_vgas_europe',
    component: () => import(/* webpackChunkName: 'ea-vgas-europe' */ '../../views/ea-vgas/regions/Europe.vue'),
    props: commonProps
  },
  {
    path: `${basePath}/asia`,
    name: 'ea_vgas_asia',
    component: () => import(/* webpackChunkName: 'ea-vgas-asia' */ '../../views/ea-vgas/regions/Asia.vue'),
    props: commonProps
  },
  {
    path: `${basePath}/japan`,
    name: 'ea_vgas_japan',
    component: () => import(/* webpackChunkName: 'ea-vgas-japan' */ '../../views/ea-vgas/regions/Japan.vue'),
    props: commonProps
  },
  {
    path: `${basePath}/south-korea`,
    name: 'ea_vgas_south_korea',
    component: () => import(/* webpackChunkName: 'ea-vgas-south-korea' */ '../../views/ea-vgas/regions/SouthKorea.vue'),
    props: commonProps
  }
]
