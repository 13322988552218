import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/hp-nvidia',
    name: 'hp-nvidia',
    component: () => import(/* webpackChunkName: 'hp-nvidia' */ '../../views/Nvidia.vue'),
    props: {
      gaEventCategory: 'hp_nvidia',
      gaEventLabel
    }
  }
]
