import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/madden23',
    name: 'madden23',
    component: () => import(/* webpackChunkName: 'madden23' */ '../../views/Madden23.vue'),
    props: {
      gaEventCategory: 'madden23',
      gaEventLabel
    }
  }
]
