import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/prestonb2s',
    name: 'prestonb2s',
    component: () => import(/* webpackChunkName: 'prestonplayz' */ '../../views/Prestonb2s.vue'),
    props: {
      gaEventCategory: 'prestonb2s',
      gaEventLabel
    }
  }
]
