import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/omen-gaming-hub',
    name: 'omen-gaming-hub',
    component: () => import(/* webpackChunkName: 'ogh' */ '../../views/Omen.vue'),
    props: {
      gaEventCategory: 'hp_omen',
      gaEventLabel
    }
  }
]
