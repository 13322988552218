<template lang="pug">
.giveaway-list.d-flex.flex-wrap.align-items-stretch(
  :class='listJustifyStart ? "justify-content-start" : "justify-content-center"'
)
  giveaway-tile.page-mb-md-alt(
    v-for='giveaway in list',
    :key='giveaway.idToken',
    :giveaway='giveaway',
    :show-prizes='showPrizes',
    :show-dates='showDates',
    :show-state='showState',
    :show-action-button='showActionButton',
    :show-title='showTitle',
    :title-classes='titleClasses',
    :action-button-variant='actionButtonVariant',
    :action-button-classes='actionButtonClasses',
    :action-button-title='actionButtonTitle',
    :action-button-title-iwp-only='actionButtonTitleIwpOnly',
    :action-button-title-ended='actionButtonTitleEnded',
    :action-button-margin-top='actionButtonMarginTop',
    :action-button-font-size='actionButtonFontSize',
    :date-format='dateFormat',
    :title-align='titleAlign',
    :title-size='titleSize',
    :tile-size='tileSize',
    :tile-size-sm='tileSizeSm',
    :tile-size-md='tileSizeMd',
    :tile-size-lg='tileSizeLg',
    :tile-size-xl='tileSizeXl',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel',
    :class='tileClasses'
  )
</template>
<script>
import gaEventMixin from '../../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  props: {
    list: {
      type: Array,
      required: true
    },
    listJustifyStart: {
      type: Boolean,
      required: false,
      default: false
    },
    showPrizes: {
      type: Boolean,
      required: false,
      default: true
    },
    showDates: {
      type: Boolean,
      required: false,
      default: false
    },
    showState: {
      type: Boolean,
      required: false,
      default: true
    },
    showActionButton: {
      type: Boolean,
      required: false,
      default: false
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    actionButtonVariant: {
      type: String,
      required: false,
      default: 'surf-primary'
    },
    actionButtonClasses: {
      type: String,
      required: false,
      default: ''
    },
    actionButtonTitle: {
      type: String,
      required: false,
      default: 'Enter'
    },
    actionButtonTitleIwpOnly: {
      type: String,
      required: false,
      default: 'Claim Here'
    },
    actionButtonTitleEnded: {
      type: String,
      required: false,
      default: 'View Winners'
    },
    actionButtonMarginTop: {
      type: String,
      required: false,
      default: '3'
    },
    actionButtonFontSize: {
      type: String,
      required: false,
      default: ''
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    headerClasses: {
      type: String,
      required: false,
      default: ''
    },
    titleClasses: {
      type: String,
      required: false,
      default: ''
    },
    tileClasses: {
      type: String,
      required: false,
      default: ''
    },
    titleAlign: {
      type: String,
      required: false,
      default: 'left' // supports 'left', 'right', 'center'
    },
    titleSize: {
      type: String,
      required: false,
      default: 'md' // supports all fs- class sizes
    },
    tileSize: {
      type: String,
      required: false,
      default: 'sm' // supports 'sm', 'md', 'lg', 'full', 'half'
    },
    tileSizeSm: {
      type: String,
      required: false,
      default: ''
    },
    tileSizeMd: {
      type: String,
      required: false,
      default: ''
    },
    tileSizeLg: {
      type: String,
      required: false,
      default: ''
    },
    tileSizeXl: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
