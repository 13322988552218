<template lang="pug">
page
  page-section(slot='content', align='center')
    .page-my-xxl(slot='content')
      h1 404 - Not Found
      p Oops, looks like we can't find the page you're looking for.
</template>

<script>
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  metaInfo() {
    return {
      title: 'Not Found (404)',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
