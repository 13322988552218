import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/polaris-bf2042',
    name: 'polaris_bf2042',
    component: () => import(/* webpackChunkName: 'polaris-bf2042' */ '../../views/PolarisBF2042.vue'),
    props: {
      gaEventCategory: 'polaris_bf20424',
      gaEventLabel
    }
  }
]
