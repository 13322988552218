import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/bf',
    name: 'bf2042',
    component: () => import(/* webpackChunkName: 'battlefield2042' */ '../../views/Battlefield2042.vue'),
    props: {
      gaEventCategory: 'bf2042_gold',
      gaEventLabel
    }
  }
]
