import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/wild-hearts',
    name: 'wild-hearts',
    component: () => import(/* webpackChunkName: 'ea-wildhearts' */ '../../views/ea-wild-hearts/WildHearts.vue'),
    props: {
      gaEventCategory: 'wild-hearts',
      gaEventLabel
    }
  }
]
