<template lang="pug">
.giveaway-tile.d-flex.flex-column.align-items-start(
  :class='giveawayClasses',
  v-bind='linkAttrs',
  :data-id-token='idToken',
  @mousedown='trackEvent(gaEventAction)'
)
  .giveaway-tile__inner
    .giveaway-tile__main
      .giveaway-tile__img.mb-1
        img(:src='imgSrc', :alt='title', :title='title')
      h3.giveaway-tile__title.mb-0(v-if='showTitle', :class='`text-${titleAlign}`')
        span.giveaway-tile__title-title(:class='allTitleClasses') {{ title }}
        span.giveaway-tile__state.fs-xs.uppercase(v-if='showState', :class='stateClasses') {{ stateName }}
    .giveaway-tile__dates.mt-1.pl-1(v-if='showDates && !giveaway.isSuspended && !giveaway.isDraft')
      template(v-if='giveaway.isUpcoming') Starting {{ formattedStartDate }}
      template(v-else-if='giveaway.isActive') Ends {{ formattedEndDate }}
      template(v-else-if='giveaway.isEnded') Ended {{ formattedEndDate }}
    .giveaway-tile__prize-list.d-flex.flex-column.mt-2.pl-1(v-if='prizeList.length && showPrizes')
      .giveaway-tile__prize-list-item.d-flex.flex-align-start.text-left(v-for='prize in prizeList')
        i.icon.icon-sm.icon-inline.mr-2(:class='$icons.prize')
        span.copy {{ prize }}
    .giveaway-tile__enter-btn.w-100(
      v-if='showActionButton && ["active", "ended"].includes(stateName)',
      :class='`text-${titleAlign} mt-${actionButtonMarginTop}`'
    )
      b-btn(:class='buttonClasses', :variant='actionButtonVariant')
        span.copy.w-100
          template(v-if='giveaway.isEnded') {{ actionButtonTitleEnded }}
          template(v-else-if='giveaway.iwpOnly') {{ actionButtonTitleIwpOnly }}
          template(v-else) {{ actionButtonTitle }}
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import gaEventMixin from '../../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  data() {
    return {
      stateName: 'upcoming',
      stateInterval: null
    }
  },
  props: {
    giveaway: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    showPrizes: {
      type: Boolean,
      required: false,
      default: true
    },
    showDates: {
      type: Boolean,
      required: false,
      default: false
    },
    showState: {
      type: Boolean,
      required: false,
      default: true
    },
    showActionButton: {
      type: Boolean,
      required: false,
      default: false
    },
    headerClasses: {
      type: String,
      required: false,
      default: ''
    },
    titleClasses: {
      type: String,
      required: false,
      default: ''
    },
    actionButtonVariant: {
      type: String,
      required: false,
      default: 'surf-primary'
    },
    actionButtonClasses: {
      type: String,
      required: false,
      default: ''
    },
    actionButtonTitle: {
      type: String,
      required: false,
      default: 'Enter'
    },
    actionButtonTitleIwpOnly: {
      type: String,
      required: false,
      default: 'Claim Here'
    },
    actionButtonTitleEnded: {
      type: String,
      required: false,
      default: 'View Winners'
    },
    actionButtonMarginTop: {
      type: String,
      required: false,
      default: '3'
    },
    actionButtonFontSize: {
      type: String,
      required: false,
      default: ''
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'M/D/YYYY'
    },
    newTab: {
      type: Boolean,
      required: false,
      default: true
    },
    titleAlign: {
      type: String,
      required: false,
      default: 'left' // supports 'left', 'right', 'center'
    },
    titleSize: {
      type: String,
      required: false,
      default: 'md' // supports all fs- class sizes
    },
    tileSize: {
      type: String,
      required: false,
      default: 'sm' // supports 'sm', 'md', 'lg', 'full', 'half'
    },
    tileSizeSm: {
      type: String,
      required: false,
      default: ''
    },
    tileSizeMd: {
      type: String,
      required: false,
      default: ''
    },
    tileSizeLg: {
      type: String,
      required: false,
      default: ''
    },
    tileSizeXl: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    idToken() {
      return this.giveaway.record.idToken
    },
    title() {
      return this.giveaway.record.title
    },
    imgSrc() {
      return this.giveaway.record.image
    },
    prizeList() {
      const prizes = cloneDeep(this.giveaway.record.rewards)
      prizes.sort((a, b) => (a.position > b.position ? 1 : -1))
      return prizes.map((reward) => reward.content.title)
    },
    linkAttrs() {
      if (this.stateName === 'upcoming') {
        return {}
      }

      return {
        is: 'a',
        href: `${this.$helpers.giveawayUrl(this.idToken)}${this.stateName === 'ended' ? '/ended' : ''}`,
        target: this.newTab ? '_blank' : '_self'
      }
    },
    giveawayClasses() {
      const classes = [`giveaway-tile--${this.stateName}`]
      const tileSizes = [
        {
          prefix: '',
          tileSize: this.tileSize
        },
        {
          prefix: 'sm-',
          tileSize: this.tileSizeSm
        },
        {
          prefix: 'md-',
          tileSize: this.tileSizeMd
        },
        {
          prefix: 'lg-',
          tileSize: this.tileSizeLg
        },
        {
          prefix: 'xl-',
          tileSize: this.tileSizeXl
        }
      ]

      tileSizes.forEach((size) => {
        if (size.tileSize && ['sm', 'md', 'lg', 'full', 'half'].includes(size.tileSize)) {
          classes.push(`giveaway-tile--size-${size.prefix}${size.tileSize}`)
        }
      })

      return classes.join(' ')
    },
    allTitleClasses() {
      const classes = [`fs-${this.titleSize} ${this.titleClasses}`]

      if (this.showState) {
        classes.push('pr-3')
      }

      return classes.join(' ')
    },
    buttonClasses() {
      const classes = []

      if (this.actionButtonClasses) {
        classes.push(this.actionButtonClasses)
      }

      if (!this.actionButtonClasses.includes('px-')) {
        classes.push('px-4')
      }

      if (this.actionButtonFontSize) {
        classes.push(`fs-${this.actionButtonFontSize}`)
      }

      return classes.join(' ')
    },
    stateClasses() {
      if (this.stateName === 'active') {
        return 'color-soft-purple'
      }

      if (this.stateName === 'ended') {
        return 'color-red'
      }

      return 'color-inherit'
    },
    gaEventAction() {
      return this.stateName === 'upcoming' ? '' : `to_giveaway__${this.idToken}`
    },
    formattedStartDate() {
      return this.giveaway.formatStartDate(this.dateFormat)
    },
    formattedEndDate() {
      return this.giveaway.formatEndDate(this.dateFormat)
    }
  },
  watch: {
    stateName() {
      if (this.stateName === 'ended') {
        clearInterval(this.stateInterval)
      }
    }
  },
  created() {
    this.giveawayStateCheck()
    this.stateInterval = setInterval(this.giveawayStateCheck, 1000)
  },
  beforeDestroy() {
    clearInterval(this.stateInterval)
  },
  methods: {
    giveawayStateCheck() {
      this.stateName = this.giveaway.isActive ? 'active' : this.giveaway.isEnded ? 'ended' : 'upcoming'
    }
  }
}
</script>
