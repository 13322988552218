import Page from './Page'
import PageSection from './PageSection'
import GiveawayTile from './GiveawayTile'
import GiveawayList from './GiveawayList'
import LoadingIcon from './LoadingIcon'
import SurfLogo from './SurfLogo'
import UserDisplay from './UserDisplay'

export default [
  {
    label: 'SurfLogo',
    component: SurfLogo
  },
  {
    label: 'Page',
    component: Page
  },
  {
    label: 'PageSection',
    component: PageSection
  },
  {
    label: 'GiveawayTile',
    component: GiveawayTile
  },
  {
    label: 'GiveawayList',
    component: GiveawayList
  },
  {
    label: 'LoadingIcon',
    component: LoadingIcon
  },
  {
    label: 'UserDisplay',
    component: UserDisplay
  }
]
