export default {
  chevronDown: 'fas fa-chevron-down icon-chevron-down',
  chevronUp: 'fas fa-chevron-up icon-chevron-up',
  chevronLeft: 'fas fa-chevron-left icon-chevron-left',
  chevronRight: 'fas fa-chevron-right icon-chevron-right',
  close: 'fal fa-times icon-close',
  externalLink: 'fas fa-external-link icon-external-link',
  loading: 'fas fa-circle-notch fa-spin icon-loading',
  mapPin: 'fas fa-map-marker-alt icon-map-pin',
  mute: 'fas fa-volume-mute icon-mute',
  pause: 'fas fa-pause icon-pause',
  play: 'fas fa-play icon-play',
  prize: 'fas fa-gift icon-prize',
  ticket: 'icon-ticket',
  unmute: 'fas fa-volume-up icon-unmute'
}
