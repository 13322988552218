import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/rockstar-energy',
    name: 'rockstar_energy',
    component: () => import(/* webpackChunkName: 'rockstar-energy' */ '../views/RockstarEnergy.vue'),
    props: {
      gaEventCategory: 'rockstar_energy',
      gaEventLabel
    }
  }
]
