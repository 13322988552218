import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/it-takes-two',
    name: 'itt',
    component: () => import(/* webpackChunkName: 'itt' */ '../../views/ItTakesTwo.vue'),
    props: {
      gaEventCategory: 'it_takes_two',
      gaEventLabel
    }
  }
]
