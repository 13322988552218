<template lang="pug">
footer.base-footer(role='navigation', :class='footerWrapClasses')
  slot(name='footer-prepend')
  .base-footer__main.d-flex.flex-wrap.align-items-center.justify-content-center(
    :class='footerMainClasses'
  )
    slot(name='custom-logo')
      surf-logo(
        size='sm-alt',
        :centered='true',
        :link-to-home='linkToHome',
        :link-new-tab='true',
        :trufan-attribution='true',
        :color='logoColor',
        :ga-event-category='gaEventCategory',
        :ga-event-label='gaEventLabel'
      )
    b-nav.base-footer__nav(v-if='showFooterNav', :class='footerNavClasses')
      li(v-if='showTermsLink')
        slot(name='custom-terms-link')
          a(
            :href='termsUrl',
            target='_blank',
            @mousedown='trackEvent("to_tos__footer")'
          ) Terms
      li(v-if='showPrivacyLink')
        slot(name='custom-privacy-link')
          a(
            :href='privacyUrl',
            target='_blank',
            @mousedown='trackEvent("to_privacy__footer")'
          ) Privacy
      li(v-if='supportLink.length > 0')
        a(
          :href='supportLink',
          target='_blank',
          @mousedown='trackEvent("to_support__footer")'
        ) Support
      slot(name='extra-nav-li')
    .base-footer__custom-image(v-if='$slots["custom-image"]')
      slot(name='custom-image')
  slot(name='footer-append')
</template>
<script>
import gaEventMixin from '../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  props: {
    logoColor: {
      type: String,
      required: false,
      default: 'white'
    },
    borderTop: {
      type: Boolean,
      required: false,
      default: true
    },
    showTermsLink: {
      type: Boolean,
      required: false,
      default: true
    },
    showPrivacyLink: {
      type: Boolean,
      required: false,
      default: true
    },
    linkToHome: {
      type: Boolean,
      required: false,
      default: false
    },
    termsLink: {
      type: String,
      required: false,
      default: ''
    },
    privacyLink: {
      type: String,
      required: false,
      default: ''
    },
    supportLink: {
      type: String,
      required: false,
      default: ''
    },
    asRowBp: {
      // When to display horizontally as a row
      type: String,
      required: false,
      default: 'sm' // One of: 'sm', 'md', 'lg', or 'xl'
    }
  },
  computed: {
    footerWrapClasses() {
      const classes = []

      if (this.borderTop) {
        classes.push('base-footer--bordered')
      }

      if (this.supportLink.length > 0) {
        classes.push('base-footer--support')
      }

      return classes.join(' ')
    },
    footerMainClasses() {
      return `flex-column flex-${this.asRowBp}-row`
    },
    footerNavClasses() {
      const classes = [
        `flex-grow-1 justify-content-${this.asRowBp}-end`, // alignment
        `page-mt-sm-alt ${this.asRowBp}:page-mt-0 ${this.asRowBp}:page-ml-sm-alt` // spacing
      ]

      return classes.join(' ')
    },
    showFooterNav() {
      return this.showTermsLink || this.showPrivacyLink || this.supportLink || this.$slots['extra-nav-li']
    },
    termsUrl() {
      return this.termsLink || this.$surf.links.terms
    },
    privacyUrl() {
      return this.privacyLink || this.$surf.links.privacy
    }
  }
}
</script>
