<template lang="pug">
.surf-user-wrap(:class='wrapperClass')
  .surf-user(:class='userClass')
    .surf-user__avatar(v-if='showAvatar && avatarUrl.length', :class='avatarDisplayClass')
      img(:src='avatarUrl', :title='fullAccountName')
    .surf-user__name-wrap(v-if='showName', :class='nameWrapClass')
      .surf-user__name-prepend(v-if='hasNamePrependSlot')
        slot(name='name-prepend')
      .surf-user__name-main(:class='nameMainClass')
        .surf-user__name(:class='nameClass', :title='fullAccountName')
          slot(name='name') {{ userName }}
        .surf-user__name-discriminator(v-if='showDiscriminator', :class='discriminatorClass') {{ userDiscriminator }}
      .surf-user__name-append(v-if='hasNameAppendSlot', :class='nameAppendClass')
        slot(name='name-append')
</template>
<script>
import truncate from 'lodash/truncate'

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    showAvatar: {
      type: Boolean,
      required: false,
      default: true
    },
    showName: {
      type: Boolean,
      required: false,
      default: true
    },
    showDiscriminator: {
      type: Boolean,
      required: false,
      default: true
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    wrap: {
      type: Boolean,
      required: false,
      default: true
    },
    ellipsis: {
      type: Boolean,
      required: false,
      default: false
    },
    nameOverride: {
      type: String,
      required: false,
      default: ''
    },
    truncateName: {
      type: Boolean,
      required: false,
      default: false
    },
    textSize: {
      type: String,
      required: false,
      default: 'md' // Values handled in CSS: sm, md, lg, xl
    },
    avatarSrc: {
      type: String,
      required: false,
      default: ''
    },
    avatarSrcSize: {
      type: String,
      required: false,
      default: 'medium'
    },
    avatarDisplaySize: {
      type: String,
      required: false,
      default: 'sm' // Values handled in CSS: mini, sm, md, lg, lg-alt, xl, xxl
    },
    align: {
      type: String,
      required: false,
      default: 'left' // Values handled in CSS: left, left-sm, left-md, ..., right, right-sm, right-md, ...
    },
    alignUntil: {
      type: String,
      required: false,
      default: '' // Values handled in CSS: sm, md, lg, xl, xxl
    },
    possessive: {
      type: Boolean,
      required: false,
      default: false
    },
    fontsizeInherit: {
      type: Boolean,
      required: false,
      default: false
    },
    colorInherit: {
      type: Boolean,
      required: false,
      default: false
    },
    extraNameClass: {
      type: String,
      required: false,
      default: ''
    },
    extraDiscriminatorClass: {
      type: String,
      required: false,
      default: ''
    },
    extraNameAppendClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    wrapperClass() {
      const classes = []

      if (this.inline) {
        classes.push('d-inline')
      }

      return classes.join(' ')
    },
    userClass() {
      const classes = []

      if (this.inline) {
        classes.push('d-inline')
      } else {
        classes.push('d-inline-flex')
      }

      if (this.ellipsis) {
        classes.push('surf-user--name-ellipsis')
      }

      if (this.wrap && !this.ellipsis) {
        classes.push('text-wrap')
      } else {
        classes.push('text-nowrap')
      }

      if (this.align.length) {
        classes.push(`surf-user--align-${this.align}`)
      } else {
        classes.push('surf-user--align-left')
      }

      if (this.alignUntil.length && this.align.length) {
        classes.push(`surf-user--align-${this.align}-until-${this.alignUntil}`)
      }

      return classes.join(' ')
    },
    inlineClass() {
      const classes = []

      if (this.inline) {
        classes.push('d-inline')
      } else {
        classes.push('d-inline-block')
      }

      return classes.join(' ')
    },
    nameWrapClass() {
      const classes = []
      classes.push(this.inlineClass)
      classes.push(`surf-user__name-wrap--${this.textSize}`)

      if (this.fontsizeInherit) {
        classes.push('surf-user__name-wrap--fs-inherit')
      }

      if (this.colorInherit) {
        classes.push('surf-user__name-wrap--color-inherit')
      }

      if (this.wrap && !this.ellipsis) {
        classes.push('text-wrap')
      }

      return classes.join(' ')
    },
    nameMainClass() {
      const classes = []

      if (this.inline || this.wrap) {
        classes.push('d-inline')
      }

      if (this.ellipsis) {
        classes.push('d-flex align-items-baseline')
      }

      return classes.join(' ')
    },
    nameClass() {
      const classes = []

      if (this.showDiscriminator && this.userDiscriminator.length) {
        classes.push('surf-user__name--has-discriminator')
      }

      if (this.extraNameClass.length) {
        classes.push(this.extraNameClass)
      }

      return classes.join(' ')
    },
    discriminatorClass() {
      const classes = []

      if (this.extraDiscriminatorClass.length) {
        classes.push(this.extraDiscriminatorClass)
      }

      return classes.join(' ')
    },
    nameAppendClass() {
      const classes = []

      if (this.inline) {
        classes.push('d-inline')
      }

      if (this.extraNameAppendClass.length) {
        classes.push(this.extraNameAppendClass)
      }

      return classes.join(' ')
    },
    avatarDisplayClass() {
      const classes = []
      classes.push(this.inlineClass)
      classes.push(`surf-user__avatar--${this.avatarDisplaySize}`)

      return classes.join(' ')
    },
    avatarUrl() {
      if (this.avatarSrc.length) {
        return this.avatarSrc
      }

      if (!this.user.avatar || !this.user.avatar[this.avatarSrcSize]) {
        return ''
      }

      return this.user.avatar[this.avatarSrcSize]
    },
    fullAccountName() {
      return `${this.user.username}#${this.user.discriminator}`
    },
    userName() {
      let displayName = this.user.username

      if (this.nameOverride.trim().length) {
        return this.nameOverride.trim()
      }

      if (this.truncateName) {
        displayName = truncate(this.user.username, {
          length: 12
        })
      }

      if (this.possessive && !this.showDiscriminator) {
        displayName = `${displayName}'s`
      }

      return displayName
    },
    userDiscriminator() {
      if (!this.user.discriminator) {
        return ''
      }

      let discriminator = this.user.discriminator

      if (this.possessive && this.showDiscriminator) {
        discriminator = `${discriminator}'s`
      }

      return `#${discriminator}`
    },
    hasNamePrependSlot() {
      return !!this.$slots['name-prepend']
    },
    hasNameAppendSlot() {
      return !!this.$slots['name-append']
    }
  }
}
</script>
