<template lang="pug">
page
  page-section(slot='content', align='center')
    .page-my-xxl(slot='content')
      h1 Surf Giveaways
      p You will be redirected to
        a.px-1(:href='$surf.links.giveaways')
          | {{ $surf.links.giveaways }}
        | in {{ counter }} seconds.
</template>
<script>
import gaEventMixin from '@/mixins/ga_event'

export default {
  name: 'Home',
  mixins: [gaEventMixin],
  data() {
    return {
      counterInterval: null,
      counter: 4
    }
  },
  created() {
    this.counterInterval = setInterval(() => {
      if (this.counter === 0) {
        this.trackEvent('auto-redirect_to__browse')
        window.location = this.$surf.links.giveaways
      } else {
        this.counter -= 1
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.counterInterval)
  },
  metaInfo() {
    return {
      title: 'Welcome',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
