<template lang="pug">
.surf-layout(:class='wrapperClasses')
  .surf-layout__section(:class='sectionClasses')
    slot(name='content')
  slot(name='append')
</template>
<script>
export default {
  props: {
    thin: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidthUntil: {
      type: String,
      required: false,
      default: null
    },
    align: {
      type: String,
      required: false,
      default: 'left' // 'center' or 'right'
    }
  },
  computed: {
    wrapperClasses() {
      if (this.thin) {
        return 'surf-layout--thin'
      }

      if (this.fullWidth) {
        return 'surf-layout--default'
      }

      return 'surf-layout--contained'
    },
    sectionClasses() {
      const classes = [`text-${this.align}`]

      if (this.fullWidth) {
        if (['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'].includes(this.fullWidthUntil)) {
          classes.push(`surf-layout__section--fullwidth-until-${this.fullWidthUntil}`)
        } else {
          classes.push('surf-layout__section--fullwidth')
        }
      } else {
        classes.push('surf-layout__section--default')
      }

      return classes.join(' ')
    }
  }
}
</script>
