import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import EaBfRoutes from './ea/ea-bf-routes'
import EaIttRoutes from './ea/ea-itt-routes'
import EaMaddenRoutes from './ea/ea-madden-routes'
import EaPolarisRoutes from './ea/ea-polaris-routes'
import EaStarWarsRoutes from './ea/ea-sw-routes'
import EaVgaRoutes from './ea/ea-vgas-routes'
import EaWildHeartsRoutes from './ea/ea-wh-routes'
import RockstarEnergyRoutes from './rockstar-energy-routes'
import Prestonb2sRoutes from './prestonplayz/prestonb2s-routes'
import ChallengeResponseRoutes from './challenge-response/challenge-response-routes'
import NotFound from '@/views/NotFound'
import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'
import HpNvidiaRoutes from './hp/hp-nvidia-routes'
import HpOmenRoutes from './hp/hp-omen-routes'
import AmazonLolRoutes from './amazon/amazon-lol-routes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: {
      gaEventCategory: 'home',
      gaEventLabel
    }
  },
  ...EaBfRoutes,
  ...EaIttRoutes,
  ...EaMaddenRoutes,
  ...EaPolarisRoutes,
  ...EaStarWarsRoutes,
  ...EaWildHeartsRoutes,
  ...EaVgaRoutes,
  ...RockstarEnergyRoutes,
  ...Prestonb2sRoutes,
  ...ChallengeResponseRoutes,
  ...HpNvidiaRoutes,
  ...HpOmenRoutes,
  ...AmazonLolRoutes,
  {
    path: '*',
    name: '404',
    component: NotFound,
    props: {
      gaEventCategory: '404',
      gaEventLabel
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
