import Vue from 'vue'
import Meta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue'
import VueGtag from 'vue-gtag'
import VueLocalStorage from 'vue-localstorage'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'

import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import GlobalComponents from '@/components/global/'
import surfConfig from '@/config/surf'
import iconConfig from '@/config/icons'
import helpers from '@/utils/helpers'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueLocalStorage)
Vue.use(Meta)
Vue.use(
  VueGtag,
  {
    config: {
      id: 'UA-205113044-3',
      enabled: !process.env.VUE_APP_GIVEAWAY_DOMAIN || process.env.VUE_APP_GIVEAWAY_DOMAIN === 'giveaways.joinsurf.com'
    }
  },
  router
)
Vue.use(VueFacebookPixel, {
  appId: '302474920690179',
  router
})

GlobalComponents.forEach((global) => {
  Vue.component(global.label, global.component)
})

Vue.prototype.$surf = surfConfig
Vue.prototype.$icons = iconConfig
Vue.prototype.$helpers = helpers
Vue.prototype.$breakpoints = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1645
}

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
