<template lang="pug">
#app
  router-view
</template>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'App'
}
</script>
<style lang="scss" src="./assets/scss/app.scss" />
