<template lang="pug">
#appContainer.app-container(:class='internalContainerClasses')
  #appHeader.app-container__headers(v-if='showHeader')
    slot(name='header')
      base-header(
        :show-header-links='showHeaderLinks',
        :ga-event-category='gaEventCategory',
        :ga-event-label='gaEventLabel'
      )
  #appContent.app-container__content
    .app-container__content-outer(:class='internalContentClasses')
      b-container.app-container__content-inner(:fluid='true')
        .app-container__content-view(:class='internalViewClasses')
          slot(name='content')
        #appFooter.app-container__footers(v-if='showFooter')
          slot(name='footer')
            base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
</template>
<script>
import BaseHeader from '../BaseHeader'
import BaseFooter from '../BaseFooter'
import gaEventMixin from '../../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  props: {
    pageMetaTitle: {
      type: String,
      required: false,
      default: null
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeaderLinks: {
      type: Boolean,
      required: false,
      default: true
    },
    bodyClasses: {
      type: String,
      required: false,
      default: null
    },
    contentClasses: {
      type: String,
      required: false,
      default: null
    },
    viewClasses: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    internalContainerClasses() {
      const classes = [`app-container--${this.$route.name}`]

      if (!this.showHeader) {
        classes.push('app-container--no-header')
      }

      if (!this.showFooter) {
        classes.push('app-container--no-footer')
      }

      return classes.join(' ')
    },
    internalContentClasses() {
      const classes = [`app-container__content-outer--${this.$route.name}`]

      if (this.contentClasses) {
        classes.push(this.contentClasses)
      }

      return classes.join(' ')
    },
    internalViewClasses() {
      const classes = [`app-container__content-view--${this.$route.name}`]

      if (this.viewClasses) {
        classes.push(this.viewClasses)
      }

      return classes.join(' ')
    }
  },
  created() {
    const bodyClasesIterator = document.body.classList.values()

    for (const className of bodyClasesIterator) {
      if (className.includes('app-body')) {
        document.body.classList.remove(className)
      }
    }

    document.body.classList.add(`app-body--${this.$route.name}`)

    if (this.bodyClasses) {
      document.body.classList.add(this.bodyClasses)
    }
  },
  metaInfo() {
    if (this.pageMetaTitle) {
      return {
        title: this.pageMetaTitle,
        titleTemplate: '%s | Surf Giveaways'
      }
    }

    return null
  },
  components: {
    BaseHeader,
    BaseFooter
  }
}
</script>
