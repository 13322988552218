<template lang="pug">
.loading-animation(v-if='show', :class='wrapClasses')
  .loading-animation-inner
    i.loading-animation-icon(:class='iconClasses')
</template>
<script>
export default {
  data() {
    return {
      show: false
    }
  },
  props: {
    wait: {
      type: Number,
      required: false,
      default: 200
    },
    size: {
      type: String,
      required: false,
      default: 'lg'
    },
    color: {
      type: String,
      required: false,
      default: 'soft-purple'
    },
    fullpage: {
      type: Boolean,
      required: false,
      default: false
    },
    grow: {
      type: Boolean,
      required: false,
      default: true
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    iconClasses() {
      const classes = [this.$icons.loading, `color-${this.color}`]

      if (['tiny', 'mini', 'std', 'sm', 'md', 'md-alt', 'lg', 'xl', 'xl'].includes(this.size)) {
        classes.push(`loading-animation-icon--${this.size}`)
      } else {
        classes.push('loading-animation-icon--lg')
      }

      return classes.join(' ')
    },
    wrapClasses() {
      const classes = []

      if (this.fullpage) {
        classes.push('vh-50')
      }

      if (this.grow) {
        classes.push('loading-animation--grow')
      }

      if (this.inline) {
        classes.push('loading-animation--inline')
      }

      return classes.join(' ')
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, this.wait)
  }
}
</script>
