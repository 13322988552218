<template lang="pug">
.surf-logo-wrap(:class='`surf-logo-wrap--theme-${color}`')
  .surf-logo(
    v-bind='logoLinkAttributes',
    :class='logoClasses',
    title='Surf Giveaways',
    @mousedown='trackEvent(gaEventActionSurf)'
  )
    img.surf-logo__img(:src='logoSrc', width='285', height='80', alt='Surf Giveaways')
</template>
<script>
import gaEventMixin from '../../mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  data() {
    return {
      surfLogoFullWhite: require('../../assets/images/surf-giveaways-logo--white.svg'),
      surfLogoFullDark: require('../../assets/images/surf-giveaways-logo--dark.svg')
    }
  },
  props: {
    noLink: {
      type: Boolean,
      required: false,
      default: false
    },
    trufanAttribution: {
      type: Boolean,
      required: false,
      default: false
    },
    linkToHome: {
      type: Boolean,
      required: false,
      default: false
    },
    linkToBrowse: {
      type: Boolean,
      required: false,
      default: true
    },
    linkNewTab: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'md' // Values: xs, sm, sm-alt, md, lg, xl
    },
    sizeSm: {
      type: String,
      required: false,
      default: ''
    },
    sizeMd: {
      type: String,
      required: false,
      default: ''
    },
    sizeLg: {
      type: String,
      required: false,
      default: ''
    },
    sizeXl: {
      type: String,
      required: false,
      default: ''
    },
    sizeXxl: {
      type: String,
      required: false,
      default: ''
    },
    sizeXxxl: {
      type: String,
      required: false,
      default: ''
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'white' // one of 'white' or 'dark'
    }
  },
  computed: {
    logoSrc() {
      return this.color === 'white' ? this.surfLogoFullWhite : this.surfLogoFullDark
    },
    gaEventActionSurf() {
      if (this.linkToHome) {
        return 'to_home__logo'
      } else if (this.linkToBrowse) {
        return 'to_browse__logo'
      }

      return null
    },
    logoLinkAttributes() {
      if (this.noLink) {
        return {}
      }

      // Link to homepage
      if (this.linkToHome) {
        return {
          is: 'a',
          href: this.$surf.links.home,
          target: this.linkNewTab ? '_blank' : '_self'
        }
      }

      // Link to browse page
      if (this.linkToBrowse) {
        return {
          is: 'a',
          href: this.$surf.links.giveaways,
          target: this.linkNewTab ? '_blank' : '_self'
        }
      }

      return {}
    },
    logoClasses() {
      const classes = []
      const logoSizes = [
        {
          prefix: '',
          logoSize: this.size
        },
        {
          prefix: 'sm-',
          logoSize: this.sizeSm
        },
        {
          prefix: 'md-',
          logoSize: this.sizeMd
        },
        {
          prefix: 'lg-',
          logoSize: this.sizeLg
        },
        {
          prefix: 'xl-',
          logoSize: this.sizeXl
        },
        {
          prefix: 'xxl-',
          logoSize: this.sizeXxl
        },
        {
          prefix: 'xxxl-',
          logoSize: this.sizeXxxl
        }
      ]

      logoSizes.forEach((size) => {
        if (size.logoSize) {
          classes.push(`surf-logo--size-${size.prefix}${size.logoSize}`)
        }
      })

      if (this.centered) {
        classes.push('justify-content-center')
      }

      return classes.join(' ')
    }
  }
}
</script>
