import { GA_EVENT_LABEL as gaEventLabel } from '@/utils/constants'

export default [
  {
    path: '/amazon-lol',
    name: 'amazon-lol',
    component: () => import(/* webpackChunkName: 'amazon-lol' */ '../../views/Lol.vue'),
    props: {
      gaEventCategory: 'amazon_lol',
      gaEventLabel
    }
  }
]
